<template>
    <v-list dense color="primary">
          <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="white--text text-h6">  <v-img
                :src="require('@/assets/whitelogo.svg')"
                contain
                width="100"
              ></v-img></v-list-item-title>
            <v-list-item-subtitle class="white--text text-subtitle-1 ml-1">ยินดีต้อนรับ</v-list-item-subtitle>
          </v-list-item-content>
             <v-badge
                    bordered
                    color="white"
                    overlap
                    bottom
                    offset-x="30"
                    offset-y="30"
                    @click="$router.push({name: 'setting'})"
                  >
                    <template slot="badge">
                      <v-icon style="font-size: 12px !important;"> mdi-pencil</v-icon>
                    </template>
                      <v-list-item-avatar size="65" @click="$router.push({name: 'setting'})">
            <v-img :src="imageProfile || require('@/assets/whiteavatar.svg')" @error="($auth.idpPicture)? imageProfile = $auth.idpPicture : imageProfile = require('@/assets/whiteavatar.svg')"></v-img>
          </v-list-item-avatar>
                  </v-badge>
            <!-- <v-list-item-avatar size="65" @click="$router.push({name: 'setting'})">
           <v-img :src="imageProfile || require('@/assets/whiteavatar.svg')" @error="($auth.idpPicture)? imageProfile = $auth.idpPicture : imageProfile = require('@/assets/whiteavatar.svg')"></v-img>
          </v-list-item-avatar> -->
        </v-list-item>
       </v-list>
</template>

<script>
import { getProfilePicture } from "@/api/"
export default {
   props: {
      title: {
        type: String,
        default: ''
      },
    },
    data() {
        return {
            site: null,
            tenant: null,
            imageProfile: null
        }
    },
  methods: {
    fetchData() {
       const role = this.$offlineStorage.get('role')
      getProfilePicture({profilePicture: role.profilePicture},message=>{
        if(message.data.code===1){
            this.imageProfile = message.data.result
            // console.log(this.imageProfile)
        }
      },error=>{
        console.log(error)
        console.log(this.$auth.idpPicture)
      })
    }
    },
    mounted() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  }
}
</script>

<style>

</style>