<template>
  <v-card>
      <v-card-title class="primary--text text-h6">เลือกหน่วยบริการ<v-spacer/><v-btn icon color="primary" @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
        <v-row>
              <v-col cols="12" v-for="(item,index) in $auth.allSite" :key="index">
              <MethodCard :title="item.siteName" @onClick="selectItem(item)" filename="addservicemenu.svg"/>
             </v-col>
        </v-row>
      </v-card-text>
  </v-card>
</template>

<script>
import MethodCard from "@/components/formlist/MethodCard"

export default {

  components: {
        MethodCard,
  },
  computed: {
  },
  methods: {
    selectItem(item) {
         this.$offlineStorage.set("site", item);
         this.$emit('onChange')
    }
  },
}
</script>
<style lang="scss" scoped>

</style>