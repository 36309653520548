<template>
    <v-list color="primary">
        
        <v-img
        class="mx-auto"
                :src="require('@/assets/whitelogo.svg')"
                contain
                width="64"
              />
          <v-list-item>
          <v-list-item-content>
            <!-- <v-list-item-title class="white--text text-h6 text-wrap">{{title}}</v-list-item-title> -->
               <v-list-item-title>
        <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            height="55"
            class="elevation-0 white--text text-h6 text-wrap pl-0 ml-0"
          >
            {{title}} <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
  
        <v-list>
          <template v-for="(item,index) in $auth.allSite" >
          <v-list-item :key="index" @click="selectItem(item)">
            <v-list-item-title>{{item.siteName}}</v-list-item-title>
          </v-list-item>
          </template>
          <!-- <v-list-item @click="onClick">
            <v-list-item-title>Option 1</v-list-item-title>
          </v-list-item>
  
          <v-list-item disabled>
            <v-list-item-title>Option 2</v-list-item-title>
          </v-list-item> -->
  
          <v-list-item @click="$router.push({name: 'qrscan'})">
            <v-list-item-title>เพิ่มสถานพยาบาล</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
               </v-list-item-title>
            <v-list-item-subtitle class="white--text text-subtitle-1">{{ $t('main.Welcome') }}</v-list-item-subtitle>
          </v-list-item-content>
                  <v-badge
                    bordered
                    color="white"
                    overlap
                    bottom
                    offset-x="30"
                    offset-y="30"
                    @click="$router.push({name: 'setting'})"
                  >
                    <template slot="badge">
                      <v-icon style="font-size: 12px !important"> mdi-pencil</v-icon>
                    </template>
                      <v-list-item-avatar size="65" @click="$router.push({name: 'setting'})">
            <v-img :src="imageProfile || require('@/assets/whiteavatar.svg')" @error="($auth.idpPicture)? imageProfile = $auth.idpPicture : imageProfile = require('@/assets/whiteavatar.svg')"></v-img>
          </v-list-item-avatar>
                  </v-badge>
        </v-list-item>
       </v-list>
</template>

<script>
import { getProfilePicture } from "@/api/"
export default {
    props: {
      title: {
        type: String,
        default: ''
      },
    },
    data() {
        return {
            site: null,
            tenant: null,
            imageProfile: null
        }
    },
    methods: {
    onClick() {

    },
    selectItem(item) {
      // console.log(item)
         this.$offlineStorage.set("site", item);
         this.$offlineStorage.set("tenant", item);
         this.$emit('onChange')
    },
    fetchData() {
       const role = this.$offlineStorage.get('role')
      getProfilePicture({profilePicture: role.profilePicture},message=>{
        if(message.data.code===1){
            this.imageProfile = message.data.result
            // console.log(this.imageProfile)
        }
      },error=>{
        console.log(error)
        console.log(this.$auth.idpPicture)
      })
    }
    },
    mounted() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
}
</script>

<style scoped>
::v-deep .v-btn__content { width: 100%; white-space: normal; text-align: left; }
</style>