<template>
    <v-card tile elevation="1" @click="$router.push({name: route})" :disabled="disabled" class="fill-height">
        <v-card-text class="text-center ml-0 mr-0 pl-0 pr-0">
            <v-img class="mx-auto" contain width="80" :src="require(`@/assets/${filename}`)"></v-img>
            <div class="text-subtitle-1 primary--text">{{ $t(title)}}</div>
          </v-card-text>
        </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        filename: {
            type: String,
            default: 'dailymenu@2x.svg'
        },
        route: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }

    },
}
</script>

<style>

</style>