<template>
  <v-app>
    <v-main>
      <v-container class="ma-0 pa-0">
        <template v-if="!site">
          <EmptyStateAppBar />
        </template>
        <template v-else>
          <ServiceStateAppBar :title="site.siteName"  @onChange="siteChange()"/>
        </template>
        <v-row dense class="pa-0 ma-0">
          <v-col class="pa-0 ma-0" cols="6"
            ><v-btn
              block
              color="primary darken-3 white--text"
              elevation="1"
              tile
              @click="qrdialog = true"
              ><v-icon class="pa-2">mdi-qrcode</v-icon>MY QR</v-btn
            ></v-col
          >
          <v-col class="pa-0 ma-0" cols="6"
            ><v-btn
              block
              color="primary darken-4 white--text"
              elevation="1"
              tile
              @click="$router.push({ name: 'qrscan' })"
              ><v-icon class="pa-2">mdi-qrcode-scan</v-icon>SCAN QR</v-btn
            ></v-col
          >
        </v-row>
        <!-- <BannerCarousel /> -->
        <v-row class="ma-0 pa-0">
          <template v-if="!site">
            <v-col
              cols="4"
              class="ma-0 pa-0"
              v-for="(item, index) in menuEmptyState"
              :key="index"
            >
              <MenuCard
                :title="item.title"
                :filename="item.filename"
                :route="item.route"
                :disabled="item.disabled"
              />
            </v-col>
          </template>
          <template v-else-if="site.serviceType === 'cms'">
            <v-col
              cols="4"
              class="ma-0 pa-0"
              v-for="(item, index) in menuCMSState"
              :key="index"
            >
              <MenuCard
                :title="item.title"
                :filename="item.filename"
                :route="item.route"
                :disabled="item.disabled"
              />
            </v-col>
          </template>
          <template v-else-if="site.serviceType === 'screening'">
            <v-col
              cols="4"
              class="ma-0 pa-0"
              v-for="(item, index) in menuScreeningState"
              :key="index"
            >
              <MenuCard
                :title="item.title"
                :filename="item.filename"
                :route="item.route"
                :disabled="item.disabled"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col
              cols="4"
              class="ma-0 pa-0"
              v-for="(item, index) in menuScreeningState"
              :key="index"
            >
              <MenuCard
                :title="item.title"
                :filename="item.filename"
                :route="item.route"
                :disabled="item.disabled"
              />
            </v-col>
          </template>
        </v-row>
      </v-container>
      <SnackBarAds v-if="!site" v-model="snackbar" timeout="-1">
        กรุณายืนยันตัวตนก่อนเข้ารับบริการ สอบถามข้อมูลเพิ่มเติมติดต่อ Line: @VWELL
        <template v-slot:action="{ attrs }">
          <v-btn
            color="primary"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            ปิด
          </v-btn>
        </template>
      </SnackBarAds>
      <SnackBarAds v-else-if="site.serviceType === 'screening'" v-model="snackbar" timeout="-1">
          กรุณาเลือกเมนู ตอบแบบฟอร์ม เพื่อส่งข้อมูลให้หน่วยบริการ
           <template v-slot:action="{ attrs }">
          <v-btn
            color="primary"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            ปิด
          </v-btn>
        </template>
      </SnackBarAds>
      <v-dialog
        transition="dialog-bottom-transition"
        fullscreen
        v-model="qrdialog"
      >
        <MyQRCard @onClose="qrdialog = false" :id="$auth.user.uniqueId" />
      </v-dialog>
      <v-dialog v-model="siteDialog">
        <SelectSiteCard @onClose="siteDialog=false" @onChange="siteChange()"/>
      </v-dialog>
    </v-main>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import EmptyStateAppBar from "@/components/main/EmptyStateAppBar";
import ServiceStateAppBar from "@/components/main/ServiceStateAppBar";
import MenuCard from "@/components/main/MenuCard";
// import BannerCarousel from "@/components/main/BannerCarousel";
import MyQRCard from "@/components/main/MyQRCard";
import SelectSiteCard from "@/components/main/SelectSiteCard";
import SnackBarAds from "@/components/main/SnackBarAds";
import { user, deleteService,updateUserProfile, postTokenCommService, postAutoTrust } from "@/api/";
// import { postPatients } from '@/api/cms'
import firebase from "firebase/app";
import { mapFields } from "vuex-map-fields";


export default {
  components: {
    EmptyStateAppBar,
    ServiceStateAppBar,
    MenuCard,
    // BannerCarousel,
    MyQRCard,
    SelectSiteCard,
    SnackBarAds
  },
  data() {
    return {
      imageProfile: null,
      loading: false,
      site: null,
      tenant: null,
      role: {
        fullname: "",
      },
      service: null,
      qrdialog: false,
      siteDialog: false,
      snackbar: true,
      menuEmptyState: [
        {
          title: "ประกาศ/ข่าวสาร",
          filename: "announcemenu.svg",
          route: "inbox",
          disabled: false,
        },
        {
          title: "main.Shopping",
          filename: "shoppingmenu.svg",
          route: "shopping",
          disabled: false,
        },
        {
          title: "main.Healthrecords",
          filename: "healthrecordmenu.svg",
          route: "healthrecord",
          disabled: false,
        },
         {
          title: "main.Message",
          filename: "message.svg",
          route: "chat",
          disabled: false,
        },
        {
          title: "main.Authentication",
          filename: "addservicemenu.svg",
          route: "kyc",
          disabled: false,
        },
      ],
      menuScreeningState: [
        {
          title: "ตอบแบบฟอร์ม",
          filename: "screeningmenu.svg",
          route: "formlist",
          disabled: false,
        },
        {
          title: "ประวัติแบบฟอร์ม",
          filename: "historymenu.svg",
          route: "done",
          disabled: false,
        },
         {
          title: "main.Healthrecords",
          filename: "healthrecordmenu.svg",
          route: "healthrecord",
          disabled: false,
        },
        {
          title: "ประกาศ/ข่าวสาร",
          filename: "announcemenu.svg",
          route: "inbox",
          disabled: false,
        },
        {
          title: "ข้อความ",
          filename: "message.svg",
          route: "chat",
          disabled: false,
        },
        {
          title: "ร้านค้า",
          filename: "shoppingmenu.svg",
          route: "shopping",
          disabled: false,
        },
        // {
        //   title: "ยืนยันตัวตน",
        //   filename: "addservicemenu.svg",
        //   route: "kyc",
        //   disabled: false,
        // },
      ],
      menuCMSState: [
        {
          title: "main.Healthrecords",
          filename: "healthrecordmenu.svg",
          route: "healthrecord",
          disabled: false,
        },
        {
          title: "main.MakeAppointment",
          filename: "bookingmenu.svg",
          route: "makeappointment",
          disabled: false,
        },
        {
          title: "main.MedicalHistory",
          filename: "fasttrackmenu.svg",
          route: "patient",
          disabled: false,
        },
        {
          title: "main.AnnouncementsNews",
          filename: "announcemenu.svg",
          route: "inbox",
          disabled: false,
        },
        // {
        //   title: "main.Message",
        //   filename: "message.svg",
        //   route: "chat",
        //   disabled: false,
        // },
        {
          title: "main.Laboratory",
          filename: "dailymenu.png",
          route: "laboratory",
          disabled: false,
        },
        {
          title: "main.Language",
          filename: "lang.png",
          route: "lang",
          disabled: false,
        },
      
        // {
        //   title: "ยืนยันตัวตน",
        //   filename: "addservicemenu.svg",
        //   route: "kyc",
        //   disabled: false,
        // },
      ],
    };
  },
  methods: {
    siteChange() {
      this.site = this.$offlineStorage.get("site");
      // console.log( this.site)
      this.siteDialog = false
    },
    updateUserId() {
       this.$gtag.set({
      'userId': this.$auth.user.uniqueId
    })
    },
    async initChat() {
       try{
        const chat = await postTokenCommService()
        if (chat.data.code === 1) {
            let service = chat.data.result.filter(el=> el.service === 'personalScreeningService')
            if(service.length > 0) {
              this.chatToken = service[0].communicationService.token
              this.chatUser = service[0].communicationService.user
              window.chatClient = new this.$ChatClient('https://comservicevwell.communication.azure.com', new this.$AzureCommunicationTokenCredential(this.chatToken));          
            }
        }
      }catch (error) {
        console.log(error)
      }
     },
     updateFCMToken() {
      if (firebase.messaging.isSupported()) {
            this.$messaging
              .getToken({
                vapidKey:
                  "BN1BEc17Qr4aZ8ONCRT44IdOIORUdrijl5OxwjcEpMBllTjTPzAal2lgaRN-driCmcOpKCyyzoP0eCNQgvQFKIE",
              })
              .then((currentToken) => {
                if (currentToken) {
                  console.log(currentToken);
                  user(message=>{
                    if(message.data){
                      // console.log(message.data)
                      updateUserProfile({id: message.data.result.id, etag:  message.data.result._etag },{firebaseToken: currentToken},message=>{
                        if (message.data.code === 1) {
                          console.log('update user profile success')
                        }
                        // console.log(message)
                      },error=>{
                    console.log(error)
                  })
                    }
                  },error=>{
                    console.log(error)
                  })
                } else {
                  // Show permission request.
                  console.log(
                    "No registration token available. Request permission to generate one."
                  );
                }
              })
              .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
              });
          }
    },
    async prepareAgain() {
      this.loading = true;
      const allsite = [];
      user(
        async (message) => {
          if (message.data.code === 1) {
           this.$auth.role = message.data.result
            const personalScreeningServices = message.data.result.service.filter(
              (val) => val.service === "personalScreeningService"
            );
            for (const service of personalScreeningServices) {
              if (service.tenant.length === 0) {
                // Trust service
              }
              for (const tenant of service.tenant) {
                for (const site of tenant.site) {
                  if (site.serviceType === "cms") {
                    if (!site.profile) {
                      try {
                        // console.log('delete service')
                        await deleteService({
                          tenantId: tenant.tenantId,
                          siteId: site.siteId,
                        });
                        tenant.site.splice(site, 1);
                         // console.log('data splice')
                         // console.log(tenant.site)
                      } catch (error) {
                        console.log(error);
                      }
                    }else{
                                       allsite.push({
                    ...site,
                    tenantId: tenant.tenantId,
                    tenantName: tenant.tenantName,
                  });
                    }
                  }else{
                    allsite.push({
                    ...site,
                    tenantId: tenant.tenantId,
                    tenantName: tenant.tenantName,
                  });
                  }
 
                }
              }
            }
            // Reassign Role and service
            message.data.result.service = personalScreeningServices;
            this.$auth.role = message.data.result;
            this.$auth.allSite = allsite;
            //console.log('assign all sites')
            //console.log( this.$auth.allSite)
            this.$offlineStorage.set("role", this.$auth.role);

            try {
              this.site = this.$offlineStorage.get("site");
              if (!this.site) {
                for (const service of personalScreeningServices) {
                  for (const tenant of service.tenant) {
                    if (Array.isArray(tenant.site)) {
                      this.$offlineStorage.set(
                        "site",
                        {...tenant.site[tenant.site.length - 1], tenantId: tenant.tenantId,
                    tenantName: tenant.tenantName}
                      );
                      this.$offlineStorage.set("tenant", tenant);
                      this.site = tenant.site[tenant.site.length - 1];
                    }
                  }
                }
              } else {
                let sitePermission = allsite.filter(item => item.siteId === this.site.siteId)
                if (sitePermission.length === 0)  {
                  if(allsite.length === 0) {
                  this.$offlineStorage.set("site", null);
                  this.$offlineStorage.set("tenant", null);
                  this.site = null
                  }else{
                  this.$offlineStorage.set("site", allsite[0]);
                  this.$offlineStorage.set("tenant", allsite[0]);
                  this.site = allsite[0]
                  }

                } else {
                  console.log('validated permission')
                }
              }
            } catch (error) {
              console.log(error);
            }
            this.loading = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async prepareAvailableService() {
      this.loading = true;
      const allsite = [];
      user(
        async (message) => {
          if (message.data.code === 1) {
           this.$auth.role = message.data.result
            const personalScreeningServices = message.data.result.service.filter(
              (val) => val.service === "personalScreeningService"
            );
            for (const service of personalScreeningServices) {
              if (service.tenant.length === 0) {
                try {
                  const resp = await postAutoTrust()
                  if (resp.data.status === 'success') {
                    this.$auth.logout()
                  }
                  //this.prepareAgain()
                  return;
                } catch(ex){
                  console.log(ex)
                }
                // Trust service
              }
              for (const tenant of service.tenant) {
                for (const site of tenant.site) {
                  if (site.serviceType === "cms") {
                    if (!site.profile) {
                      try {
                        // console.log('delete service')
                        await deleteService({
                          tenantId: tenant.tenantId,
                          siteId: site.siteId,
                        });
                        tenant.site.splice(site, 1);
                         // console.log('data splice')
                         // console.log(tenant.site)
                      } catch (error) {
                        console.log(error);
                      }
                    }else{
                                       allsite.push({
                    ...site,
                    tenantId: tenant.tenantId,
                    tenantName: tenant.tenantName,
                  });
                    }
                  }else{
                    allsite.push({
                    ...site,
                    tenantId: tenant.tenantId,
                    tenantName: tenant.tenantName,
                  });
                  }
 
                }
              }
            }
            // Reassign Role and service
            message.data.result.service = personalScreeningServices;
            this.$auth.role = message.data.result;
            this.$auth.allSite = allsite;
            //console.log('assign all sites')
            //console.log( this.$auth.allSite)
            this.$offlineStorage.set("role", this.$auth.role);

            try {
              this.site = this.$offlineStorage.get("site");
              if (!this.site) {
                for (const service of personalScreeningServices) {
                  for (const tenant of service.tenant) {
                    if (Array.isArray(tenant.site)) {
                      this.$offlineStorage.set(
                        "site",
                        {...tenant.site[tenant.site.length - 1], tenantId: tenant.tenantId,
                    tenantName: tenant.tenantName}
                      );
                      this.$offlineStorage.set("tenant", tenant);
                      this.site = tenant.site[tenant.site.length - 1];
                    }
                  }
                }
              } else {
                let sitePermission = allsite.filter(item => item.siteId === this.site.siteId)
                if (sitePermission.length === 0)  {
                  if(allsite.length === 0) {
                  this.$offlineStorage.set("site", null);
                  this.$offlineStorage.set("tenant", null);
                  this.site = null
                  }else{
                  this.$offlineStorage.set("site", allsite[0]);
                  this.$offlineStorage.set("tenant", allsite[0]);
                  this.site = allsite[0]
                  }

                } else {
                  console.log('validated permission')
                }
              }
            } catch (error) {
              console.log(error);
            }
            this.loading = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async fetchData() {
      this.loading = true;
      try {
        this.role = this.$offlineStorage.get("role");
        this.site = this.$offlineStorage.get("site");
        this.visit = null
        this.prepareAvailableService();
        this.updateFCMToken();
        this.updateUserId()
        // this.initChat()
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
  computed: {
   ...mapFields(['visit','chatToken','chatUser'])
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style>
</style>