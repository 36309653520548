<template>
  <v-card tile>
      <v-card-title>
          <v-spacer/>
             <v-spacer/>
  <div class="text-title-1 primary--text">คิวอาร์ของฉัน</div>
  <v-spacer/>
  <v-btn icon @click="$emit('onClose')"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
          
<v-img :src="url"/>
<!-- <div class="text-center">{{id}}</div> -->
      </v-card-text>
  </v-card>
</template>

<script>
import QRCode from 'qrcode'

export default {
data() {
    return {
        url: ""
    }
},
props: {
    id: {
        type: String,
        default: ''
    }
},
mounted () {
    this.renderQR(this.id);
},
methods: {
   renderQR (data) {
      QRCode.toDataURL(data)
        .then(url => {
          this.url = url
        })
        .catch(err => {
          console.error(err)
        })
    }
},
}
</script>

<style>

</style>